/*
 * It's Happening.
 * Copyright © 2019 It's Happening.
 */

import React from 'react';
import { Link, graphql } from 'gatsby';
import { kebabCase } from 'lodash';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

export const tagPageQuery = graphql`
    query TagPage($tag: String) {
        site {
            siteMetadata {
                title
            }
        }
        allMarkdownRemark(
            limit: 1000
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { frontmatter: { tags: { in: [$tag] } } }
        ) {
            totalCount
            edges {
                node {
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                    }
                }
            }
        }
    }
`;

const TagRoute = ({ data, pageContext }) => {
    const posts = data.allMarkdownRemark.edges;
    const { tag } = pageContext;
    const { totalCount } = data.allMarkdownRemark;
    const tagHeader = `${totalCount} post${
        totalCount === 1 ? '' : 's'
    } tagged with “${tag}”`;

    return (
        <Layout>
            <SEO
                title={tag}
                pathname={`/tags/${kebabCase(tag)}/`}
                type="article"
            />
            <section className="section">
                <div className="container content">
                    <div className="columns">
                        <div
                            className="column is-10 is-offset-1"
                            style={{ marginBottom: '6rem' }}
                        >
                            <h3 className="title is-size-4 is-bold-light">
                                {tagHeader}
                            </h3>
                            <ul className="taglist">
                                {posts.map(post => (
                                    <li key={post.node.fields.slug}>
                                        <Link to={post.node.fields.slug}>
                                            <h2 className="is-size-2">
                                                {post.node.frontmatter.title}
                                            </h2>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                            <p>
                                <Link to="/tags/">Browse all tags</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default TagRoute;
